import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CustomButton from "../../Component/CustomButton/CustomButton";
import RKTextField from "../../Component/Common/InputFields/RKTextField/RKTextField";
import { useRef, useState } from "react";
import TextArea from "../../Component/Common/InputFields/TextArea/TextArea";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import "./../../CommonSCSS/CMS.scss";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../SupportingFiles/colours";
import SelectDropdown from "../../Component/Common/InputFields/SelectDropdown/SelectDropdown";
import Footer from "../../Component/Desktop/Footer/Footer";

function NeedHelp() {
  const isMobile = useIsMobile();
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    insurer: { value: string; warn_status: boolean };
    policyNumber: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claimfor: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    insurer: { value: "", warn_status: false },
    policyNumber: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claimfor: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const claimForData = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Term Insurance",
      value: "Term Insurance",
    },
    {
      label: "Car Insurance",
      value: "Car Insurance",
    },
    {
      label: "Bike Insurance",
      value: "Bike Insurance",
    },
  ];
  const insurerData = [
    {
      label: "Bajaj Allianz",
      value: "Bajaj Allianz",
    },
    {
      label: "Birla Sun Life",
      value: "Birla Sun Life",
    },
  ];
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box
        sx={{
          position: "relative",
          marginTop: "124px",
          marginBottom: "100px",
        }}
      >
        <Grid
          container
          spacing={3}
          className="claimQuestion"
          alignItems={"center"}
        >
          <Grid xs={12} sm={6}>
            <h3 style={{ color: COLORS.themeblue }}>
              Need <span style={{ color: COLORS.themered }}>Help?</span>
            </h3>
          </Grid>
          <Grid xs={12} sm={6}>
            <img
              src="../images/complaint.svg"
              width="100%"
              style={{ maxWidth: "488px" }}
            />
          </Grid>

          <Grid xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                background: "rgba(33, 52, 105, 0.08)",
                padding: "32px",
                marginTop: "60px",
                "@media (max-width:899px)": {
                  padding: "32px 8px",
                  marginTop: "30px",
                },
              }}
            >
              <Grid container spacing={3}>
                <Grid xs={12}>
                  {" "}
                  <h4 style={{ color: COLORS.themeblue }}>
                    Kickstart Your Career With Us
                  </h4>
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Name"}
                    value={formFields.name.value}
                    attrName={"name"}
                    value_update={updateFieldsState}
                    warn_status={formFields.name.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Insurer"
                    attrName={"insurer"}
                    value={formFields.insurer.value}
                    value_update={updateFieldsState}
                    data={insurerData}
                    warn_status={formFields.insurer.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Policy Number"}
                    value={formFields.policyNumber.value}
                    attrName={"policyNumber"}
                    value_update={updateFieldsState}
                    warn_status={formFields.policyNumber.warn_status}
                  />
                </Grid>

                <Grid xs={12} sm={6} lg={4}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Mobile"}
                    value={formFields.mobile.value}
                    attrName={"mobile"}
                    value_update={updateFieldsState}
                    warn_status={formFields.mobile.warn_status}
                  />
                  <RKTextField
                    class_name="inputField"
                    title={"Email"}
                    value={formFields.email.value}
                    attrName={"email"}
                    value_update={updateFieldsState}
                    warn_status={formFields.email.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={8} textAlign={"center"}>
                  <TextArea
                    class_name="inputField mb-6"
                    title={"Describe"}
                    value={formFields.message.value}
                    attrName={"message"}
                    value_update={updateFieldsState}
                    warn_status={formFields.message.warn_status}
                    placeholder="Write here..."
                    rows={4}
                  />
                  <CustomButton
                    text_name="Submit Complaint"
                    class_name="regularPrimaryBtn"
                    fullWidth={isMobile ? false : true}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
}

export default NeedHelp;
