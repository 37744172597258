import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MHome from "../../Pages/Mobile/MHomePage/MHome";
import Home from "../../Pages/Desktop/HomePage/Home";

const HomeContainer = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MHome /> : <Home />;
};

export default HomeContainer;
