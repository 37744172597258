import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/CMS.scss";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import Footer from "../../Component/Desktop/Footer/Footer";
import { COLORS } from "../../SupportingFiles/colours";
// import { COLORS } from "../../SupportingFiles/colors";

function CancellationAndRefunds() {
  return (
    <Box>
      <Navbar />
      <Grid container spacing={3} className="cmsPages">
        <Grid xs={12} textAlign="center">
          <h3 style={{ color: COLORS.themeblue }}>
            Cancellation &{" "}
            <span style={{ color: COLORS.themered }}>Refund</span>
          </h3>
        </Grid>
        <Grid xs={12}>
          <p>
            The cancellation of the policy and/or the refund of the premium is
            processed as follows:
          </p>

          <h5>Cancellation of the policy by you</h5>
          <p>
            If you don't agree to certain terms and conditions in your policy,
            you can cancel it any time before the renewal date. To initiate a
            cancellation, you need to write an application to the insurance
            company stating the reason for cancelling the policy. The insurance
            company or insurer referred to here is the company whose policy you
            have purchased. Depending on when you have applied for cancellation
            your request will be processed. The period of cancellation will be
            considered as the time when the insurance company receives the
            written request.
          </p>

          <h5>Cancellation within the free-look period</h5>
          <p>
            Free-look period is a 15-day period post purchase, that most
            insurance companies give their customers to review the policy and
            cancel it if required. The Insurance Regulatory and Development
            Authority of India (IRDAI) has mandated free-look period for life
            insurance policies and health insurance policies with policy periods
            of 3 years or more. However, most insurance companies have a
            free-look period for health insurance policies with a policy period
            of less than 3 years too.
          </p>
          <span className=" d-block text-muted mt-3">
            If you request a cancellation of your policy within the free-look
            period, you get a refund of your premium excluding:
          </span>

          <ul className="mt-3 text-muted">
            <li>Health check-up expenses</li>
            <li>Stamp duty</li>
            <li>
              The proportionate risk premium of the period for which you were
              covered.
            </li>
          </ul>
          <p className="text-muted">
            {" "}
            Also, please note that the free-look provision is not available for
            renewals.
          </p>

          <h5>Cancellation Beyond Free-Look Period</h5>
          <p>
            If you apply for cancellation of the policy beyond the free-look
            period then the policy cancellation will be processed as per the
            underwriting of the policy. Also, the amount of premium will be
            refunded will be equal to what has been defined in the cancellation
            clause of your policy wording.If you wish to cancel a policy you
            have purchased from our portal, you can contact us over the phone or
            on email and we will assist you through the process.
          </p>

          <h5>Cancellation by the Insurance Company</h5>
          <p>
            The insurance company has the right to cancel your policy in the
            event of fraud, misrepresentation, non-disclosure of important facts
            or non-cooperation by the insured members. In such cases, the
            insurance company will give notice of 30-days and the premium will
            not be refunded.
          </p>

          <h5>Refund of the Premium</h5>
          <p>
            The premium that you pay is collected directly by the insurance
            company. If you claim for a refund because of an incorrect
            transaction or policy cancellation, you will get an amount as per
            the insurance company's policy. The refunded amount will be given to
            you in the form of a cheque or direct transfer, as per the insurance
            company's policy.
          </p>
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
}

export default CancellationAndRefunds;
