import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAgeInDays,
  isEmpty,
  validateRegno,
} from "../../../SupportingFiles/HelpingFunction";
// import "./../../../SCSS/ProductPages.scss";
import { COLORS } from "../../../SupportingFiles/colours";
import RKTextField from "../../../Component/Common/InputFields/RKTextField/RKTextField";
import DatePicker from "../../../Component/Common/InputFields/DatePicker/DatePicker";
// import CustomButton from "../../../Component/Common/InputFields/CustomButton/CustomButton";
import SearchDropdown from "../../../Component/Common/InputFields/SearchDropdown/SearchDropdown";
import RadioText from "../../../Component/Common/InputFields/RadioText/RadioText";
import CustomCheckbox from "../../../Component/Common/InputFields/CustomCheckbox/CustomCheckbox";
import SelectDropdown from "../../../Component/Common/InputFields/SelectDropdown/SelectDropdown";
import CustomButton from "../../../Component/CustomButton/CustomButton";

function CarProductPage({
  carFormSteps,
  setCarFormSteps,
  setFormData,
  formData,
  value,
  updateMasterState,
  onChange,
  changeNCB,
  validateForm,
  ncbValue,
}: {
  carFormSteps: any;
  setCarFormSteps: Function;
  setFormData: Function;
  formData: any;
  value: any;
  updateMasterState: Function;
  onChange: Function;
  changeNCB: Function;
  validateForm: Function;
  ncbValue: any;
}) {
  const { DROPDOWN_DATA } = useAppSelector((state: any) => state.Car);
  const navigate = useNavigate();

  return (
    <Box position="relative">
      {(carFormSteps === "oldStep2" || carFormSteps === "newStep2") && (
        <Link
          sx={{
            position: "absolute",
            top: "4px",
            left: "6px",
            color: COLORS.black,
            display: "inline-block",
          }}
          onClick={() => {
            (carFormSteps === "oldStep2" && setCarFormSteps("oldStep1")) ||
              (carFormSteps === "newStep2" && setCarFormSteps("newStep1"));
            if (carFormSteps === "newStep2") {
              setFormData((prev: any) => ({
                ...prev,
                business_type: "New",
                reg_no: {
                  value: formData.reg_no.value,
                  warning: false,
                },
                reg_date: {
                  value: formData.reg_date.value,
                  warning: false,
                },
                make_model: { value: "", warning: false },
                fuel_type: { value: "", warning: false },
                variant_cc: { value: "", warning: false },
                previous_insurer: { value: "", warning: false },
                policy_expiry_date: { value: "", warning: false },
                mobile: { value: "", warning: false },
                rto: { value: formData.rto.value, warning: false },
                claimed: value,
                anti_theft_device: "No",
                automobile_association: "0",
                deductible: "0",
                make: { value: "", warning: false },
                model: { value: "", warning: false },
                pincode: { value: "", warning: false },
                owner_name: { value: "", warning: false },
                prev_ncb: "0",
                vehicle_owned: "Individual",
              }));
            } else if (carFormSteps === "oldStep2") {
              setFormData((prev: any) => ({
                ...prev,
                business_type: "Rollover",
                reg_no: {
                  value: formData.reg_no.value,
                  warning: false,
                },
                reg_date: {
                  value: formData.reg_date.value,
                  warning: false,
                },
                make_model: { value: "", warning: false },
                fuel_type: { value: "", warning: false },
                variant_cc: { value: "", warning: false },
                previous_insurer: { value: "", warning: false },
                policy_expiry_date: { value: "", warning: false },
                mobile: { value: "", warning: false },
                rto: { value: formData.rto.value, warning: false },
                claimed: value,
                anti_theft_device: "No",
                automobile_association: "0",
                deductible: "0",
                make: { value: "", warning: false },
                model: { value: "", warning: false },
                pincode: { value: "", warning: false },
                owner_name: { value: "", warning: false },
                prev_ncb: "0",
                vehicle_owned: "Individual",
              }));
            }
          }}
        >
          <ChevronLeftRoundedIcon sx={{ fontSize: "30px" }} />
        </Link>
      )}
      {carFormSteps === "oldStep1" && formData.business_type === "Rollover" && (
        <Box>
          <Grid
            container
            spacing={2}
            textAlign="center"
            justifyContent={"center"}
          >
            <Grid xs={12}>
              <h4>
                Let's not meet by accident, come and see how we protect you
              </h4>
            </Grid>
            <Grid xs={12} md={6}>
              <RKTextField
                class_name="inputField vehicleNumberField mb-3"
                title={""}
                value={formData.reg_no.value}
                attrName={"reg_no"}
                value_update={updateMasterState}
                warn_status={formData.reg_no.warning}
                error_message={
                  isEmpty(formData.reg_no.value)
                    ? "Enter Reg. Number"
                    : "Enter Valid Reg. Number"
                }
                placeholder="MH01AB2345"
                validation_type="ALPHANUMERIC"
                max_length={11}
              />
            </Grid>
            <Grid xs={12} md={6}>
              <DatePicker
                class_name="inputField mb-3"
                title={"Reg. Date"}
                value={formData.reg_date.value}
                attrName={"reg_date"}
                value_update={updateMasterState}
                warn_status={formData.reg_date.warning}
                error_message={"Select Reg. Date"}
                min_date={15 * 12}
                max_date={3}
                date_validation_type="MONTHS"
                default_date={subDays(new Date(), 90)}
              />
            </Grid>
            <Grid xs={12}>
              <CustomButton
                class_name="regularPrimaryBtn mb-2"
                text_name="Continue"
                onClickFunction={() => {
                  const data = { ...formData };
                  if (carFormSteps === "oldStep1") {
                    data.reg_no.warning = !validateRegno(data.reg_no.value);
                    data.reg_date.warning = isEmpty(data.reg_date.value);
                    setFormData(data);
                    if (!data.reg_no.warning && !data.reg_date.warning) {
                      // GET_VEHICLE_INFO();
                      setCarFormSteps("oldStep2");
                    }
                  } else {
                    setCarFormSteps("oldStep2");
                  }
                }}
                fullWidth={false}
                // variant="contained"
              />
            </Grid>

            <Grid xs={12}>
              <Link
                sx={{ color: COLORS.black }}
                onClick={() => {
                  setCarFormSteps("newStep1");

                  setFormData((prev: any) => ({
                    ...prev,
                    rto: { value: "", warning: false },
                    business_type: "New",
                  }));
                }}
              >
                Bought a{" "}
                <span style={{ color: COLORS.themeblue }}>New Car</span>
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
      {carFormSteps === "oldStep2" && formData.business_type === "Rollover" && (
        <Box>
          <Grid
            container
            columnSpacing={2}
            textAlign="center"
            justifyContent={"center"}
          >
            <Grid xs={12}>
              <h4 className="mb-3">Select your vehicle details</h4>
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-6"
                title={"Make"}
                attrName={"make"}
                value={formData.make.value}
                value_update={updateMasterState}
                warn_status={formData.make.warning}
                error_message={"Select Make"}
                data={DROPDOWN_DATA.MAKE_LIST}
              />
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-6"
                title={"Model"}
                attrName={"model"}
                value={formData.model.value}
                value_update={updateMasterState}
                warn_status={formData.model.warning}
                error_message={"Select Model"}
                data={DROPDOWN_DATA.MODEL_LIST}
              />
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField  mb-6"
                title={"Fuel Type"}
                attrName={"fuel_type"}
                value={formData.fuel_type.value}
                value_update={updateMasterState}
                data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                warn_status={formData.fuel_type.warning}
                error_message={"Select Fuel Type"}
              />
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-6"
                title={"Variant"}
                attrName={"variant_cc"}
                value={formData.variant_cc.value}
                value_update={updateMasterState}
                data={DROPDOWN_DATA.VARIANT_LIST}
                warn_status={formData.variant_cc.warning}
                error_message={"Select Variant"}
              />
            </Grid>
            <Grid xs={12} paddingTop={0}>
              <h5
                style={{
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Policy Details
              </h5>
              <p
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "12px",
                }}
              >
                Please enter all the essential details pertaining to the policy.
              </p>
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField  mb-6"
                title={"Policy Expiry"}
                value={formData.policy_expiry_date.value}
                attrName={"policy_expiry_date"}
                value_update={updateMasterState}
                warn_status={formData.policy_expiry_date.warning}
                error_message={"Select Policy Expiry Date"}
                date_validation_type="DAYS"
                min_date={calculateAgeInDays(`${formData.reg_date.value}`) - 90}
                max_date={
                  calculateAgeInDays(FORMAT_DATE_DD_MM_YYYY(`${new Date()}`)) -
                  60
                }
              />
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-6"
                title={"Previous Insurer"}
                attrName={"previous_insurer"}
                value={formData.previous_insurer.value}
                value_update={updateMasterState}
                data={DROPDOWN_DATA.PREVIOUS_INSURER_LIST}
                warn_status={formData.previous_insurer.warning}
                error_message={"Select Previous Insurer"}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-6"
                title={"Full Name"}
                value={formData.owner_name.value}
                attrName={"owner_name"}
                value_update={updateMasterState}
                validation_type="NAME"
                warn_status={formData.owner_name.warning}
                error_message={
                  isEmpty(formData.owner_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-6"
                title={"Mobile"}
                value={formData.mobile.value}
                attrName={"mobile"}
                value_update={updateMasterState}
                warn_status={formData.mobile.warning}
                max_length={10}
                validation_type="NUMBER"
                error_message={
                  isEmpty(formData.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number"
                }
              />
            </Grid>
            <Grid
              xs={12}
              display="flex"
              gap="12px"
              alignItems="center"
              justifyContent="center"
              marginBottom={"20px"}
            >
              <h6>Claimed in the last year?</h6>
              <RadioText
                count="AUTO"
                orientation="row"
                variant="small"
                attrName="claimed"
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]}
                onChange={(e: any) => {
                  onChange(e?.target?.value);
                }}
                value={value}
              />
            </Grid>
            {value === "No" ? (
              <Grid xs={12} marginBottom={"20px"}>
                <h6>Previous No Claim Bonus (NCB)</h6>
                <p
                  className="mb-2"
                  style={{
                    fontSize: "12px",
                    color: COLORS.darkGrey,
                  }}
                >
                  (Check it in your last policy)
                </p>

                <RadioText
                  count="AUTO"
                  orientation="row"
                  variant="small"
                  attrName="ncb"
                  options={[
                    { label: "0%", value: "0" },
                    { label: "20%", value: "20" },
                    { label: "25%", value: "25" },
                    { label: "35%", value: "35" },
                    { label: "45%", value: "45" },
                    { label: "50%", value: "50" },
                  ]}
                  onChange={(e: any) => {
                    changeNCB(e?.target?.value);
                  }}
                  value={ncbValue}
                />
              </Grid>
            ) : (
              value === "Yes"
            )}

            <Grid xs={12}>
              <CustomButton
                class_name="regularPrimaryBtn mb-2"
                text_name="View Quotes"
                onClickFunction={() => {
                  validateForm();
                  // setCarFormSteps("oldStep2");
                }}
                fullWidth={false}
                // variant="contained"
              />
              {/* <Link onClick={() => setCarFormSteps("oldStep1")}>
                      Back
                    </Link> */}
            </Grid>
            <Grid xs={12} className="popup-Checkbox">
              <CustomCheckbox
                // label="By clicking on view quotes you agree to all Terms & Conditions."
                label={
                  <>
                    By clicking on view quotes you agree to all {""}
                    <span
                      style={{ cursor: "pointer", color: COLORS.themeblue }}
                      onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}
                    >
                      Terms & Conditions
                    </span>
                  </>
                }
                attrName={"termCondition"}
                defaultChecked
                value={true}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {carFormSteps === "newStep1" && formData.business_type === "New" ? (
        <Box>
          <Grid
            container
            spacing={2}
            textAlign="center"
            justifyContent={"center"}
          >
            <Grid xs={12}>
              <h4>Select your vehicle RTO</h4>
            </Grid>
            <Grid xs={12} md={8}>
              <SearchDropdown
                class_name="inputField mb-4"
                title={"RTO"}
                attrName={"rto"}
                value={formData.rto.value}
                value_update={updateMasterState}
                data={DROPDOWN_DATA.RTO_LIST}
                warn_status={formData.rto.warning}
                error_message={"Select RTO"}
              />
            </Grid>

            <Grid xs={12}>
              <CustomButton
                class_name="regularPrimaryBtn mb-2"
                text_name="Continue"
                onClickFunction={() => {
                  const data = { ...formData };
                  if (carFormSteps === "newStep1") {
                    data.rto.warning = isEmpty(data.rto.value);
                    setFormData(data);
                    if (!data.rto.warning) {
                      setCarFormSteps("newStep2");
                    }
                  } else {
                    validateForm();
                  }
                }}
                fullWidth={false}
                // variant="contained"
              />
            </Grid>

            <Grid xs={12}>
              <Link
                sx={{ color: COLORS.black }}
                onClick={() => {
                  setCarFormSteps("oldStep1");
                  setFormData((prev: any) => ({
                    ...prev,
                    reg_date: { value: "", warning: false },
                    reg_no: { value: "", warning: false },
                    business_type: "Rollover",
                  }));
                }}
              >
                Old
                <span style={{ color: COLORS.themeblue }}> Car</span>
              </Link>
            </Grid>
          </Grid>
        </Box>
      ) : carFormSteps === "newStep2" ? (
        <Box>
          <Grid
            container
            columnSpacing={2}
            textAlign="center"
            justifyContent={"center"}
          >
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-6"
                title={"Make"}
                attrName={"make"}
                value={formData.make.value}
                value_update={updateMasterState}
                warn_status={formData.make.warning}
                error_message={"Select Make"}
                data={DROPDOWN_DATA.MAKE_LIST}
              />
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-6"
                title={"Model"}
                attrName={"model"}
                value={formData.model.value}
                value_update={updateMasterState}
                warn_status={formData.model.warning}
                error_message={"Select Make"}
                data={DROPDOWN_DATA.MODEL_LIST}
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField  mb-6"
                title={"Fuel Type"}
                attrName={"fuel_type"}
                value={formData.fuel_type.value}
                value_update={updateMasterState}
                data={DROPDOWN_DATA.FUEL_TYPE_LIST}
                warn_status={formData.fuel_type.warning}
                error_message={"Select Fuel Type"}
              />
            </Grid>
            <Grid xs={6}>
              <SearchDropdown
                class_name="inputField mb-6"
                title={"Variant"}
                attrName={"variant_cc"}
                value={formData.variant_cc.value}
                value_update={updateMasterState}
                data={DROPDOWN_DATA.VARIANT_LIST}
                warn_status={formData.variant_cc.warning}
                error_message={"Select Variant"}
              />
            </Grid>

            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-6"
                title={"Full Name"}
                value={formData.owner_name.value}
                attrName={"owner_name"}
                value_update={updateMasterState}
                validation_type="NAME"
                warn_status={formData.owner_name.warning}
                error_message={
                  isEmpty(formData.owner_name.value)
                    ? "Enter Full Name"
                    : "Enter Valid Full Name "
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField mb-6"
                title={"Mobile"}
                value={formData.mobile.value}
                attrName={"mobile"}
                value_update={updateMasterState}
                warn_status={formData.mobile.warning}
                error_message={
                  isEmpty(formData.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter Valid Mobile Number "
                }
                validation_type="NUMBER"
                max_length={10}
              />
            </Grid>

            <Grid xs={12}>
              <CustomButton
                class_name="regularPrimaryBtn mb-2"
                text_name="View Quotes"
                onClickFunction={() => {
                  validateForm();
                  // setCarFormSteps("oldStep2");
                }}
                fullWidth={false}
                // variant="contained"
              />

              {/* <Link onClick={() => setCarFormSteps("newStep1")}>Back</Link> */}
            </Grid>
            <Grid xs={12} className="popup-Checkbox">
              <CustomCheckbox
                label={
                  <>
                    By clicking on view quotes you agree to all {""}
                    <span
                      style={{ cursor: "pointer", color: COLORS.themeblue }}
                      onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}
                    >
                      Terms & Conditions
                    </span>
                  </>
                }
                attrName={"termCondition"}
                defaultChecked={true}
                checked={true}
                disabled={true}
                // value={termCondition}
                // value_update={handleCheckbox}
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
}

export default CarProductPage;
