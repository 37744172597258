import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/CMS.scss";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import Footer from "../../Component/Desktop/Footer/Footer";
import { COLORS } from "../../SupportingFiles/colours";

function Disclaimer() {
  return (
    <Box>
      <Navbar />
      <Grid container spacing={3} className="cmsPages">
        <Grid xs={12} textAlign="center">
          <h3 style={{ color: COLORS.themeblue }}>Disclaimer</h3>
        </Grid>
        <Grid xs={12}>
          <p>
            BeSecured intends to provide clear and unbiased information about
            insurance products and services. The information and data are
            generic in nature. Our efforts are to offer unbiased, accurate and
            responsible data to the best of our ability. We are however, in no
            manner or any form responsible for any discrepancies in the data
            published on our website.
          </p>

          <p>
            The information on this site is intended to be useful and
            informative for the general public. BeSecured will use reasonable
            care to ensure that information is accurate at the time it is added
            on the site. This information is subject to change and updated at
            any time without prior notice.
          </p>

          <p>
            Although we have tried to maintain high standards in quality,
            clarity and accuracy of the material posted on the portal, take My
            Policy is not legally responsible for the same in any manner what so
            ever. Partners, Employees and associated staff of BeSecured are not
            accountable for any loss, harm or damage that occurs or may occur or
            arise from the usage of information from the portal. Customers are
            advised to use their own discretion in such matters.
          </p>

          <p>
            It is important to understand that insurance is a subject matter of
            solicitation and market risks. It is the responsibility of the
            customer to understand the limitations of insurance policies and the
            risks involved, and under no circumstances, any form or manner, do
            we take any liability in such cases. We also recommend you to please
            read the subject and offer documents carefully. The information
            provided on the portal is for financial and insurance purposes.
          </p>

          <p>
            It is a mutual understanding between us and the customers that their
            association with the portal will be at the customer's sole
            discretion and risk.
          </p>

          <p>
            There is no intention or purpose of violating any intellectual
            property or ancillary rights.
          </p>

          <p>
            Visitors to this website/portal and every third party are hereby
            informed that the owner of this website/portal is Bharat Re -
            Insurance Brokers Private Limited is a licensed Insurance Broker
            holding the Insurance Broking License (Direct-Life and General) from
            Insurance Regulatory and Development Authority of India (IRDAI)
            having IRDAI License Code DB 637/15, License no. 571. Bharat Re -
            Insurance Brokers Private Limited or BeSecured is not an Insurance
            Company.
          </p>

          <p>
            Though endeavour is made to make correct policy/product comparisons,
            quotes, features, etc., based on the information provided by the
            insurers, it is made abundantly clear that Bharat Re - Insurance
            Brokers Private Limited, its Partners, directors, shareholders,
            officers and employees and BeSecured.in are in no way responsible to
            or liable for any one for his/her investment decision, and every
            prospect/ investor/policyholder shall be solely responsible for the
            consequences of his/her decision.
          </p>

          <p>
            besecured.in and Bharat Re - Insurance Brokers Private Limited
            reserves the right to contact you via any medium, not limited to
            SMS, email, or phone call, in order to offer you any additional
            services that BeSecured.in would consider you eligible for, either
            as a customer or as a visitor.
          </p>

          <h5>Blogs &amp; Articles</h5>
          <p>
            Blogs / articles posted on BeSecured.in have been authored by
            individuals (including but not limited to employees of BeSecured.in
            and Bharat Re - Insurance Brokers Private Limited shall be referred
            to as “Individuals” hereafter), and hence, the authenticity of
            content and opinions are entirely at the sole discretion of the
            individuals in their personal capacity, and may not represent
            official positions of BeSecured.in and Bharat Re - Insurance Brokers
            Private Limited in any manner.besecured.in and Bharat Re - Insurance
            Brokers Private Limited reserves all copyrights to these
            blogs/articles.
          </p>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

export default Disclaimer;
