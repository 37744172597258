import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { Box, Link } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Component/Desktop/Footer/Footer";
import MNavbar from "../../../Component/Mobile/MNavbar/MNavbar";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { COLORS } from "../../../SupportingFiles/colours";
import "./MHome.scss";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";

// Define a type for the insurance types
type InsuranceType = {
  label: string;
  type: string;
  imgSrc: string;
};

// Define a type for the FAQs
type FAQ = {
  question: string;
  answer: string;
};

const insuranceTypes: InsuranceType[] = [
  {
    label: "Health",
    type: "Insurance",
    imgSrc: "../images/health_insurance.svg",
  },
  { label: "Car", type: "Insurance", imgSrc: "../images/car_insurance.svg" },
  {
    label: "Bike",
    type: "Insurance",
    imgSrc: "../images/bike_Insurance.svg",
  },
  {
    label: "Term",
    type: "Insurance",
    imgSrc: "../images/term_insurance.svg",
  },
  {
    label: "Investment",
    type: "Plan",
    imgSrc: "../images/Investment_insurance.svg",
  },
  {
    label: "Travel",
    type: "Insurance",
    imgSrc: "../images/Travel_insurance.svg",
  },
];

const faqs: FAQ[] = [
  {
    question: "Why should I go through BeSecured?",
    answer:
      "At BeSecured, we ensure that your insurance is done to near perfection, based on your inputs.",
  },
  {
    question: "Will I have to pay extra for your services?",
    answer:
      "No we will not be taking any extra money from you. You will only have to pay the premium for the policy.",
  },
  {
    question: "How long would it take to get the policy?",
    answer:
      "We will issue the policy immediately on receipt of the premium & you will get the policy within a few hours.",
  },
  {
    question: "How will I be able to get my claims?",
    answer:
      "If you have a claim to make, you can send us a what's app message with the photo of the accident and policy details and we will take action.",
  },
  {
    question:
      "Will there be any difference in the premium between taking the policy through you or directly?",
    answer:
      "No there will not be any difference in the premium. The advantage of taking through Besecured is that you will have an executive to represent you in a claims situation.",
  },
];

function MHome() {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box className="mhomepage">
      <MNavbar />

      <Box
        sx={{
          position: "relative",
          marginBottom: "80px",
          ":before": {
            background: "#0A1534",
            width: "100%",
            height: "360px",
            content: "''",
            position: "absolute",
            top: "-50px",
            left: "0px",
            transform: "skew(0deg, 4deg)",
            zIndex: "0",
          },
        }}>
        <Grid
          container
          columnSpacing={3}
          position="relative"
          sx={{
            minHeight: "300px",
            ":before": {
              backgroundImage: 'url("../images/header-triangle.svg")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              width: "36px",
              height: "40px",
              content: "''",
              position: "absolute",
              top: "80%",
              right: "15%",
              zIndex: "0",
            },
          }}>
          <Grid
            xs={12}
            textAlign="center"
            sx={{
              color: COLORS.white,
              paddingBottom: "40px",
              paddingTop: "60px",
            }}>
            <h2 className="productHeading">
              Be secured,{" "}
              <span style={{ color: COLORS.themered }}>be assured</span>
            </h2>
            <p style={{ letterSpacing: "2px", marginTop: "24px" }}>
              Click for choice... Choose the best... Cover to besecured
            </p>
          </Grid>
        </Grid>
      </Box>

      <Box className="products-section">
        <Grid container columnSpacing={3} alignItems="center">
          <Grid xs={12} textAlign={"center"}>
            <h3 className="mb-4">Quick Quotes</h3>
          </Grid>
          <Grid xs={12}>
            <ul>
              {insuranceTypes.map((insurance, index) => (
                <li
                  key={index}
                  onClick={() => {
                    switch (insurance.label) {
                      case "Health":
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                        break;

                      case "Car":
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
                        break;

                      case "Bike":
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
                        break;

                      case "Term":
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
                        break;

                      default:
                        break;
                    }
                  }}
                  className={
                    insurance.label === "Travel" ||
                    insurance.label === "Investment"
                      ? "disabled"
                      : ""
                  }>
                  <p>
                    <span>{insurance.label}</span> {insurance.type}
                  </p>
                  <img src={insurance.imgSrc} alt="img" />
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="whyBesecured_box">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box
              sx={{
                background: "#E6EDED",
                borderRadius: "24px",
                padding: "15px",
              }}>
              <h3>Why Besecured?</h3>
              <p>
                Retail or individual insurance space has been dominated by the
                focus on price / premium to the complete disregard of more
                important factors of coverage and claims.
              </p>
              <p>Besecured is our effort to shift the focus from price.</p>
              <p>
                Yes!! Price is important - but not as important as other factors
                when we compare, choose and buy our insurance policy.
              </p>
              <ul className="whyList_m">
                <li>
                  <span>Besecured</span> guides you through the buying process.
                </li>
                <li>
                  <span>Besecured</span> clarifies the coverage, the exclusive,
                  the ass ons, and yes
                </li>
                <li>
                  <span>Besecured</span> keeps you focused on the 360 degree
                  view
                </li>
                <li>
                  <span>Besecured</span> gives the real purpose of buying a
                  policy and help with the claims process.
                </li>
                <li>
                  <span>Besecured</span> gives comparisons of the price
                  (ofcourse !)
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="knwMore_section">
        <Grid container columnSpacing={3}>
          <Grid xs={12} textAlign={"right"} marginBottom={"30px"}>
            <h3>
              Know More About
              <br /> Insurance
            </h3>
          </Grid>
          <Grid xs={12}>
            <Box className="prod-sec">
              <ul className="prod-list">
                <li>
                  <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}>
                    <img src="../images/health_ins.svg" alt="img" />
                    <p>
                      Health <span>Insurance</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                    <img src="../images/car_ins.svg" alt="img" />
                    <p>
                      Car <span>Insurance</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                    <img src="../images/bike_ins.svg" alt="img" />
                    <p>
                      Bike <span>Insurance</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <Link onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                    <img src="../images/term_ins.svg" alt="img" />
                    <p>
                      Term <span>Insurance</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <Link className="notAllowed">
                    <img src="../images/investment_ins.svg" alt="img" />
                    <p>
                      Investment <span>Plan</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <Link className="notAllowed">
                    <img src="../images/travel_ins.svg" alt="img" />
                    <p>
                      Travel <span>Insurance</span>
                    </p>
                  </Link>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} className="advantage_sec">
        <Grid xs={12} textAlign="center">
          <h3 style={{ color: COLORS.themeblue, marginBottom: "12px" }}>
            Advantages of Insuring with BharatRe
          </h3>
          <p style={{ color: COLORS.themeblue }}>
            Why do we buy insurance? How do we know if it's the right one at the
            right price?
          </p>
          <p style={{ color: COLORS.themeblue }}>
            How do we know if the policy that we buy fits our requirement
          </p>

          <ul className="advantageList_m">
            <li>
              <img src="../images/adv_img1.svg" />
            </li>
            <li>
              <h4>Claim 24x7</h4>
              <p>
                Provided policyholders a better experience while purchasing and
                renewing their insurance policies.
              </p>
            </li>
            <li>
              <h4>Photo Not Required</h4>
              <p>
                Vehicle photos not required to take an insurance with BeSecured
              </p>
            </li>

            <li>
              <img src="../images/adv_img2.svg" />
            </li>

            <li>
              <img src="../images/adv_img3.svg" />
            </li>
            <li>
              <h4>Paperless Policy</h4>
              <p>Premium Paid</p>
              <p>Policy received</p>
              <p>Policy Online</p>
            </li>
          </ul>
        </Grid>
      </Grid>

      <Grid container columnSpacing={3} className="faqs-section">
        <Grid xs={12} textAlign="center">
          <h3 style={{ color: COLORS.themeblue, marginBottom: "5px" }}>
            FAQ's
          </h3>
          <p style={{ color: COLORS.themeblue, marginBottom: "30px" }}>
            Why do we buy insurance? How do we know if it's the right one at the
            right price.
          </p>
        </Grid>
        {faqs.map((faq, index) => (
          <Grid xs={12} key={index}>
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}>
              <AccordionSummary
                expandIcon={
                  expanded === `panel${index}` ? (
                    <RemoveCircleRoundedIcon />
                  ) : (
                    <AddCircleRoundedIcon />
                  )
                }
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}>
                {faq.question}
              </AccordionSummary>
              <AccordionDetails>{faq.answer}</AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>

      <Footer />
    </Box>
  );
}

export default MHome;
