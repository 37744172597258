// DatePicker.tsx
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { subDays, subMonths, subYears } from "date-fns";
import { useEffect, useState } from "react";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";

const DatePicker = ({
  title,
  value,
  value_update,
  attrName,
  error_message = "Select",
  warn_status,
  class_name,
  min_date = 15,
  max_date = 18,
  date_validation_type = "YEARS",
  disabled = false,
  default_date = new Date(),
}: {
  title: string;
  value: any;
  value_update: Function;
  attrName: any;
  error_message?: string;
  warn_status?: boolean;
  class_name: string;
  min_date?: number;
  max_date?: number;
  date_validation_type?: "YEARS" | "MONTHS" | "DAYS";
  disabled?: boolean;
  default_date?: Date;
}) => {
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateValidation, setDateValidation] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(min_date) && !isEmpty(max_date)) {
      setDateValidation(true);
    } else {
      setDateValidation(false);
    }
  }, [min_date, max_date]);

  useEffect(() => {
    switch (date_validation_type) {
      case "YEARS":
        setMinDate(subYears(new Date(), min_date));
        break;
      case "MONTHS":
        setMinDate(subMonths(new Date(), min_date));
        break;
      case "DAYS":
        setMinDate(subDays(new Date(), min_date));
        break;

      default:
        break;
    }

    switch (date_validation_type) {
      case "YEARS":
        setMaxDate(subYears(new Date(), max_date));
        break;
      case "MONTHS":
        setMaxDate(subMonths(new Date(), max_date));
        break;
      case "DAYS":
        setMaxDate(subDays(new Date(), max_date));
        break;

      default:
        break;
    }
  }, [min_date, max_date, dateValidation]);

  const [localValue, setLocalValue] = useState<Date | null>(value);

  useEffect(() => {
    if (value !== null && value !== "") {
      const dateString = value; // Replace with your date string in DD-MM-YYYY format
      const parts = dateString.split("-");

      // Make sure to use parts[2] for the year, parts[1] for the month, and parts[0] for the day
      if (parts.length === 3) {
        const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;

        const dateObject = new Date(formattedDateString);

        if (!isNaN(dateObject.getTime())) {
          // Valid date
          setLocalValue(dateObject);
        } else {
          // Invalid date string
          console.error("Invalid date string");
        }
      } else {
        // Invalid format
        console.error("Invalid date format");
      }
    } else {
      // Handle null or empty string case
      setLocalValue(null);
      console.error("Value is null or empty");
    }
  }, [value]);

  return (
    <div className={`datepicker ${class_name}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          disabled={disabled}
          label={title}
          value={localValue}
          onChange={(selectedDate) => {
            value_update(attrName, FORMAT_DD_MM_YYYY(`${selectedDate}`));
          }}
          format="dd-MM-yyyy"
          closeOnSelect
          // defaultCalendarMonth={default_date}
          minDate={dateValidation ? minDate : undefined} // Minimum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
          maxDate={dateValidation ? maxDate : undefined} // Maximum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
        />
        {warn_status ? <span className="error">{error_message}</span> : null}
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
