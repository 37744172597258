import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/CMS.scss";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import Footer from "../../Component/Desktop/Footer/Footer";
import { COLORS } from "../../SupportingFiles/colours";
import CustomButton from "../../Component/CustomButton/CustomButton";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import HealthFormContainer from "../../Container/Health/Form/HealthFormContainer";

function HealthProductPage() {
  const isMobile = useIsMobile();
  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}

      <Box
        sx={{
          position: "relative",
          marginTop: "100px",
          ":before": {
            background: "#0A1534",
            width: "100%",
            height: "450px",
            content: "''",
            position: "absolute",
            top: "-50px",
            left: "0px",
            transform: "skew(0deg, 3deg)",
            zIndex: "-1",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} md={6} sx={{ color: COLORS.white }}>
            <h2 className="productHeading">
              <span style={{ color: COLORS.themered }}>Health</span> Insurance
            </h2>
            <p style={{ marginTop: "24px", lineHeight: "28px" }}>
              Why should one need Health Insurance? One common thing that people
              assume is that they made the decision to go without health
              insurance.
            </p>
            <img
              src="../images/health-product-img.svg"
              width="100%"
              className="productHeadImg"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                background: COLORS.white,
                borderRadius: "12px",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                padding: "32px 20px",
                marginTop: "120px",
                marginBottom: "24px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                "@media (max-width:899px)": {
                  marginTop: "24px",
                },
              }}
            >
              <HealthFormContainer />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} className="productPages">
        <Grid xs={12}>
          <p>
            When we are healthy and rarely see a doctor, it may seem like a good
            idea to cut out health insurance completely and to just go without
            it. We can pay for things as we go instead of worrying about the
            insurance premiums. However, this is a faulty reasoning. It is
            essential for everyone to always have some form of health insurance.
            The reason is that medical emergencies are expensive. If anyone have
            a medical emergency without insurance, it is easy to find them with
            a crippling amount of medical debt.
          </p>
          <h5>
            No one plans to get sick or hurt, but most people need medical care
            at some point. Health insurance covers these costs and offers many
            other important benefits.
          </h5>
          <ul>
            <li>
              Health insurance covers essential health benefits critical to
              maintaining our health and treating illness and accidents
            </li>
            <li>
              Health insurance protects us from unexpected, high medical costs.
            </li>
            <li>
              We pay less for covered in-network health care, even before we
              meet your deductible
            </li>
          </ul>
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
}

export default HealthProductPage;
