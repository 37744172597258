import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../../Component/Common/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/Common/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/Common/InputFields/RKTextField/RKTextField";
import SearchDropdown from "../../../Component/Common/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../Component/Common/InputFields/SelectDropdown/SelectDropdown";
import CustomButton from "../../../Component/CustomButton/CustomButton";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { useAppSelector } from "../../../Store/Store/hooks";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colours";
function TermsProductPage({
  annual_income_data,
  age_data,
  gender_data,
  smokinStatus_data,
  updateMasterState,
  validate_form,
  loader,
  formData,
}: {
  annual_income_data: any;
  age_data: any;
  gender_data: any;
  formData: any;
  updateMasterState: Function;
  loader: any;
  validate_form: Function;
  smokinStatus_data: any;
}) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Term } = useAppSelector((state) => state);
  return (
    <Grid container spacing={2} textAlign="center" justifyContent={"center"}>
      <Grid xs={12}>
        <h4>Insure your Family with us</h4>
      </Grid>
      <Grid xs={6}>
        <SearchDropdown
          class_name="inputField mb-2"
          title={"Annual Income"}
          attrName={"annualIncome"}
          value={`${formData.annualIncome.value}`}
          value_update={updateMasterState}
          data={annual_income_data}
          warn_status={formData.annualIncome.warning}
          error_message={"Select Annual Income"}
        />
      </Grid>
      <Grid xs={6}>
        <SelectDropdown
          class_name="inputField mb-2"
          title={"Do you smoke?"}
          attrName={"smoke"}
          value={formData.smoke.value}
          value_update={updateMasterState}
          data={smokinStatus_data}
          warn_status={formData.smoke.warning}
          error_message={"Select Smoker"}
        />
      </Grid>
      <Grid xs={6}>
        <SelectDropdown
          class_name="inputField mb-2"
          title={"Your Gender"}
          attrName={"gender"}
          value={formData.gender.value}
          value_update={updateMasterState}
          data={gender_data}
          warn_status={formData.gender.warning}
          error_message={"Select Gender"}
        />
      </Grid>
      <Grid xs={6}>
        <DatePicker
          class_name="inputField mb-2"
          title={"Date of Birth"}
          value={formData.age.value}
          attrName={"age"}
          value_update={updateMasterState}
          warn_status={formData.age.warning}
          error_message="Select DOB"
          min_date={65}
          max_date={18}
          default_date={
            formData.age.value === ""
              ? subYears(new Date(), 18)
              : subYears(new Date(), 0)
          }
        />
      </Grid>
      <Grid xs={6}>
        <RKTextField
          class_name="inputField mb-2"
          title={"Pincode"}
          value={formData.pincode.value}
          attrName={"pincode"}
          value_update={updateMasterState}
          warn_status={formData.pincode.warning}
          error_message={
            isEmpty(formData.pincode.value)
              ? "Enter Pincode"
              : "Enter Valid Pincode"
          }
          max_length={6}
          validation_type="NUMBER"
        />
      </Grid>
      <Grid xs={6}>
        <RKTextField
          class_name="inputField mb-2"
          title={"Mobile"}
          value={formData.mobile.value}
          attrName={"mobile"}
          value_update={updateMasterState}
          warn_status={formData.mobile.warning}
          error_message={
            isEmpty(formData.mobile.value)
              ? "Enter Mobile Number"
              : "Enter Valid Mobile Number"
          }
          max_length={10}
          validation_type="NUMBER"
        />
      </Grid>

      <Grid xs={12}>
        <CustomButton
          class_name="regularPrimaryBtn"
          text_name="View Quotes"
          onClickFunction={() => {
            validate_form();
          }}
          fullWidth={false}
        />
      </Grid>
      <Grid xs={12} className="popup-Checkbox">
        <CustomCheckbox
          checked={true}
          label={
            <>
              By clicking on view quotes you agree to all {""}
              <span
                style={{ cursor: "pointer", color: COLORS.themeblue }}
                onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}
              >
                Terms & Conditions
              </span>
              .
            </>
          }
          defaultChecked
          value={true}
          disabled={true}
          attrName={"termCondition"}
        />
      </Grid>
    </Grid>
  );
}

export default TermsProductPage;
