import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/CMS.scss";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import Footer from "../../Component/Desktop/Footer/Footer";
// import { COLORS } from "../../SupportingFiles/colors";
import CustomButton from "../../Component/CustomButton/CustomButton";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { COLORS } from "../../SupportingFiles/colours";
import TWProductContainer from "../../Container/TWProductContainer/TWProductContainer";

function BikeProductPage() {
  const isMobile = useIsMobile();
  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box
        sx={{
          position: "relative",
          marginTop: "100px",
          ":before": {
            background: "#0A1534",
            width: "100%",
            height: "450px",
            content: "''",
            position: "absolute",
            top: "-50px",
            left: "0px",
            transform: "skew(0deg, 3deg)",
            zIndex: "-1",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} md={6} sx={{ color: COLORS.white }}>
            <h2 className="productHeading">
              <span style={{ color: COLORS.themered }}>Bike</span> Insurance
            </h2>
            <p style={{ marginTop: "24px", lineHeight: "28px" }}>
              Vehicle insurance covers damage to your mobile assets - everything
              from Two Wheelers, Cars, vans and four-wheel drives to goods
              carrying vehicles, dumpers, forklifts, tractors and loaders.
            </p>
            <img
              src="../images/bike-product-img.svg"
              width="100%"
              className="productHeadImg"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                background: COLORS.white,
                borderRadius: "12px",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                padding: "32px 20px",
                marginTop: "120px",
                marginBottom: "24px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                "@media (max-width:899px)": {
                  marginTop: "24px",
                },
              }}
            >
              <TWProductContainer />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} className="productPages">
        <Grid xs={12}>
          <p>
            The policy covers damage to your vehicles or the property of third
            parties. Options to choose from for a Motor for Vehicle insurance
            policy include: Comprehensive damage to your vehicle, Third party
            property damage cover, Motor vehicle insurance Policies which cover
            loss or damage to private motor vehicles, trailers and the like and
            include comprehensive Motor Insurance covering against accident,
            fire, theft, and liability for damage to other vehicles and
            property. Third Party only covers damage you may cause to another
            person's vehicle or property.
          </p>
          <p>
            Having a personal vehicle is no less than a necessity these days.
            But with its ownership comes the responsibility to ride safely and
            follow all the road safety rules. Since uncertainties do not come
            knocking at our doors, all the vehicle owners need to stay prepared
            with the help of motor insurance.
          </p>
          <p>
            You might have witnessed motor insurance purchase as an utmost
            requirement at the time of buying a vehicle. But what is it and how
            is it useful for vehicle owners? This comprehensive guide to motor
            vehicle insurance will cover it all.
          </p>
          <p>
            To start with, you should know that motor vehicle insurance is an
            important document that every vehicle owner and driver should have.
            It is designed to provide protection against loss or physical damage
            incurred by the insured vehicle. It comprises both third-party and
            own-damage cover. Before we get into the details,let's start with
            the basic definition.
          </p>
          <h4>What is motor insurance?</h4>
          <p>
            In insurance terminology, motor insurance is a type of insurance
            policy that is meant for all vehicles, including two-wheelers,
            four-wheelers, and commercial trucks. A motor insurance policy
            provides financial protection against physical damage or losses
            sustained due to unforeseen events like road accidents or natural
            calamities. Buying a certain type of motor insurance is mandatory by
            law for all vehicles, including cars, scooters, or bikes plying on
            the Indian roads.
          </p>
          <h5>The features of motor vechicle insurance</h5>
          <p>
            A vehicle insurance policy has several features that are beneficial
            for the policyholder. Although the exact features of motor insurance
            depend on the policy you select, some of its common features are:
          </p>
          <ul>
            <li>Ease of buying vehicle insurance either offline or online</li>
            <li>
              Ease of renewing the insurance policy online from wherever you are
            </li>
            <li>
              Easy to include or remove add-ons or riders as per your
              requirements
            </li>
            <li>
              Coverage against damages caused in an accident along with fire
              damages, theft, and man-made disasters
            </li>
            <li>Cashless claim facility</li>
            <li>No Claim Bonus for every claim-free year</li>
          </ul>

          <h4>What are the types of insurance ?</h4>
          <p>
            Vehicle insurance is classified into three types based on the kind
            of vehicle insured under it. These include:
          </p>

          <ul>
            <li>Car insurance</li>
            <li>Bike or two-wheeler insurance</li>
            <li>Commercial vehicle insurance</li>
          </ul>

          <h4>
            What are the Types of Motor Insurance Coverage Available in India?
          </h4>
          <h5>Comprehensive vehicle insurance coverage</h5>
          <p>
            This type of vehicle insurance protects the owner of vehicles (you)
            from a financial loss incurred from damage or theft of the insured
            vehicle. In most cases,a comprehensive insurance policy includes
            third-party insurance cover. It can also be considered an add-on to
            third-party insurance.
          </p>
          <h5>Third-party motor insurance coverage</h5>
          <p>
            If you and your vehicle are the doers/cause of an accident, then
            this coverage benefits the third-party or victim of the accident.
            This type of motor insurance policy does not provide any financial
            benefit to the insured (you).
          </p>
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
}

export default BikeProductPage;
