import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import "./CustomButton.scss";

interface Props {
  class_name?: string;
  text_name: string | any;
  loading?: boolean;
  onClickFunction?: Function;
  disabled?: boolean;
  fullWidth?: boolean;
}

const CustomButton: React.FC<Props> = ({
  class_name = "regularPrimaryBtn",
  text_name,
  loading = false,
  onClickFunction = () => {},
  disabled,
  fullWidth = false,
}) => {
  return (
    <Box className="ctaBtn">
      <Button
        variant="contained"
        disableRipple={false}
        className={class_name}
        onClick={() => {
          if (loading === false) {
            onClickFunction();
          }
        }}
        disabled={disabled || loading}
        fullWidth={fullWidth}
      >
        {loading === true ? (
          <>
            <Box className="loader mr-2" />
            Loading
          </>
        ) : (
          text_name
        )}
      </Button>
    </Box>
  );
};

export default CustomButton;
