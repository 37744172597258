import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/CMS.scss";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import Footer from "../../Component/Desktop/Footer/Footer";
import { COLORS } from "../../SupportingFiles/colours";
// import { COLORS } from "../../SupportingFiles/colors";

function TermsAndConditions() {
  return (
    <Box>
      <Navbar />
      <Grid container spacing={3} className="cmsPages">
        <Grid xs={12} textAlign="center">
          <h3 style={{ color: COLORS.themeblue }}>
            <span style={{ color: COLORS.themered }}>Terms</span> of Use
          </h3>
        </Grid>
        <Grid xs={12}>
          <h5>Terms of Use Agreement</h5>
          <p>
            Bharat Re - Insurance Brokers Private Limited (hereinafter referred
            to as BeSecured) operates the website www.besecured.in (hereinafter
            referred to as besecured). BeSecured is a licensed Insurance Broking
            Company holding a broking license from the Indian Insurance
            Regulator - Insurance Regulatory and Development Authority of India
            bearing IRDAI License No. 571 licence code IRDA/DB/637/15.Through
            besecured, BeSecured provides users with information and tools to
            make it easy to compare and buy insurance policies.
          </p>
          <p>
            By visiting besecured and accessing the information, resources,
            services, products, and tools we provide, you understand and agree
            to accept and adhere to the following terms of use as stated in this
            policy (hereafter referred to as ‘ToU Agreement’), along with the
            terms and conditions as stated in our Privacy Policy.
          </p>
          <p>
            This ToU Agreement is effective from the time you visit our website.
            We reserve the right, at any time, at our sole discretion, to change
            or otherwise modify the ToU Agreement without prior notice, and your
            continued access or use of besecured signifies your acceptance of
            the updated or modified ToU Agreement.
          </p>

          <h5>Using BeSecured</h5>
          <p>
            By visiting our website and accessing the information, resources,
            services, products, and tools we provide for you, either directly or
            indirectly (hereafter referred to as ‘Resources’), you agree to use
            these Resources only for the purposes intended as permitted by the
            terms of this ToU Agreement, and/or applicable laws, regulations and
            generally accepted online practices or guidelines.
          </p>
          <span>Wherein, you understand that:</span>
          <ul>
            <li>
              In order to access our Resources, you may be required to provide
              certain information about yourself (such as name, address, contact
              details, car registration and purchase details, identity proof
              such as Aadhar ID, etc.). You agree that any information you
              provide will always be accurate, correct, and updated.
            </li>
            <li>
              By providing your information you explicitly authorize us to
              access on your behalf your records from the competent authority,
              such as Aadhar, RTO, Pan card etc. for the purpose of facilitation
              of your transaction with us.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of any
              login information associated with any account you use to access
              our Resources. Accordingly, you are responsible for all activities
              that occur under your account/s.
            </li>
            <li>
              Accessing (or attempting to access) any of our Resources by any
              means other than through the means we provide is strictly
              prohibited. You specifically agree not to access (or attempt to
              access) any of our Resources through any automated, unethical or
              unconventional means.
            </li>
            <li>
              Engaging in any activity that disrupts or interferes with our
              Resources, including the servers and/or networks to which our
              Resources are located or connected, is strictly prohibited.
            </li>
            <li>
              Attempting to copy, duplicate, reproduce, sell, trade, or resell
              our Resources is strictly prohibited.
            </li>
            <li>
              You are solely responsible for any consequences, losses, or
              damages that we may directly or indirectly incur or suffer due to
              any unauthorized activities conducted by you, as explained above,
              and may incur criminal or civil liabilities.
            </li>
          </ul>
          <h5>User generated content</h5>
          <p>
            We may provide various open communication tools on our website, such
            as blog comments, blog posts, public chat, forums, message boards,
            newsgroups, product ratings and reviews, various social media
            services, etc. You understand that we do not pre-screen content
            posted by users of these various communication tools, which means
            that if you choose to use these tools to submit any type of content
            to our website (“user generated content”), then it is your personal
            responsibility to use these tools in a responsible and ethical
            manner. By posting information or otherwise using any open
            communication tools as mentioned, you agree that you will not
            upload, post, share, or otherwise distribute any content that:
          </p>
          <ul>
            <li>
              Is illegal, threatening, defamatory, abusive, harassing,
              degrading, intimidating, fraudulent, deceptive, invasive, racist,
              or contains any type of suggestive, inappropriate, or explicit
              language.
            </li>
            <li>
              Infringes on any trademark, patent, trade secret, copyright, or
              other proprietary right of any party;
            </li>
            <li>
              Contains any type of unauthorized or unsolicited advertising;
            </li>
            <li>
              Impersonates any person or entity, including any BeSecured
              employees or representatives.
            </li>
          </ul>
          <p>
            We do not assume any liability for any user generated content posted
            by you or any other 3rd party users of our website. We have the
            right at our sole discretion to remove any content that, we feel in
            our judgment does not comply with this ToU Agreement, along with any
            content that we feel is otherwise offensive, harmful, objectionable,
            inaccurate, or violates any 3rd party copyrights or trademarks.
          </p>
          <p>
            Any user generated content posted by you using any open
            communication tools on our website, provided that it doesn't violate
            or infringe on any 3rd party copyrights or trademarks, becomes the
            property of BeSecured, and as such, gives us a perpetual,
            irrevocable, worldwide, royalty-free, exclusive license to
            reproduce, modify, adapt, translate, publish, publicly display
            and/or distribute as we see fit. This only refers and applies to
            user generated content as described. All user Personal Information,
            provided as part of our registration or purchase process, is covered
            by our Privacy Policy.
          </p>
          <h5>IPR and Copyright</h5>
          <p>
            All content and materials available on besecured, including but not
            limited to text, graphics, website name, code, images and logos are
            either the intellectual property of BeSecured or under licensed use
            by BeSecured, and are protected by applicable copyright and
            trademark law. Any inappropriate use, including but not limited to
            the reproduction, distribution, display or transmission of any
            content on this site is strictly prohibited, unless specifically
            authorized by BeSecured.
          </p>
          <h5>Indemnification</h5>
          <p>
            You agree to indemnify and hold BeSecured, its parent company,
            officers, subsidiaries, affiliates, successors, assigns, Partners,
            directors, officers, agents, service providers, suppliers and
            employees, harmless from any claim or demand, including reasonable
            attorney fees and court costs, made by any third party due to or
            arising out of content submitted by the user, users use of the
            service, violation of the ToU, breach by the user of any of the
            representations and warranties herein, or user's violation of any
            rights of another.
          </p>

          <h5>Limitation of Warranties</h5>
          <p>
            By using our website, you understand and agree that all Resources we
            provide are “as is” and “as available”. This means that we do not
            represent or warrant to you that:
          </p>
          <ul>
            <li>
              The use of our Resources will meet your needs or requirements,
            </li>
            <li>
              The use of our Resources will be uninterrupted, timely, secure or
              free from errors,
            </li>
            <li>
              The information obtained by using our Resources will be accurate
              or reliable, and
            </li>
            <li>
              Any downloads from BeSecured will not harm your computer or device
              in any way.
            </li>
          </ul>
          <p>
            To the maximum extent permitted by law, BeSecured has no liability
            in relation to or arising out of the Website Information and
            recommendations. You are responsible for the final choice of your
            product. Please seek further advice from BeSecured or the relevant
            participating insurance companies before choosing your product, if
            you have any doubts or queries about the same. Furthermore,
            BeSecured does not guarantee when or if you will actually acquire
            the product that you have chosen and does not accept any liability
            arising out of delay in you acquiring the product you have chosen.
            You acknowledge and accept that the final issuance of the policy is
            subject to the underwriting norms and sole discretion of the
            insurance company whose policy you have chosen to buy.
          </p>

          <h5>Limitation of Liability</h5>
          <p>
            You expressly understand and agree that any claim against us shall
            be limited to the amount you paid, if any. BeSecured will not be
            liable for any direct, indirect, incidental, consequential or
            exemplary loss or damages which may be incurred by you as a result
            of using our Resources.
          </p>
          <p>
            Unless otherwise expressed, BeSecured expressly disclaims all
            warranties and conditions of any kind, whether express or implied,
            including, but not limited to the implied warranties and conditions
            of merchantability, fitness for a particular purpose and
            non-infringement.
          </p>

          <h5>Termination of Use</h5>
          <p>
            You agree that we may, at our sole discretion, suspend or terminate
            your access to all or part of our website and Resources with or
            without notice and for any reason, including, without limitation,
            breach of this ToU Agreement. Any suspected illegal, fraudulent or
            abusive activity may be grounds for terminating your relationship
            and may be referred to appropriate law enforcement authorities. Upon
            suspension or termination, your right to use the Resources we
            provide will immediately cease, and we reserve the right to remove
            or delete any information that you may have on file with us,
            including any account or login information.
          </p>

          <h5>Nominee Assistance Program</h5>
          <p>
            Bharat Re - Insurance Brokers Private Limited ("BeSecured") has
            merely partnered with various third party experts in order to
            facilitate extended services to the users purchasing Term Insurance.
            The services provided by the third party(s) are provided by them in
            their capacity as an independent service provider(s). BeSecured do
            not provide the services in any capacity whatsoever. BeSecured do
            not endorse the services of the listed partners. BeSecured shall not
            be liable for any payment obligation in relation to the services
            provided under the Nominee Assistance program.
          </p>
          <p>
            BeSecured shall not be liable for any act(s)/omission(s) of the
            partners and/or any defect/deficiency in provision of the listed
            services including their refusal to provide the services. Any third
            party facility/offer(s)/service(s)/product(s) (“offer”) shall be
            subject to the terms and conditions of such third party which shall
            be applicable to the user. BeSecured do not endorse, make no
            representation and shall have no liability or obligation whatsoever
            in relation to such third party offer.
          </p>
          <p>
            All such third party offer shall be availed by user at their own
            risk and responsibility. BeSecured shall not be liable for any
            payment obligation in relation to such third party offer, which
            shall be user's responsibility.
          </p>
          <p>
            BeSecured is only an insurance broker and can assist with any
            insurance claims only to the extent of liasioning with the insurance
            company. Any insurance claim shall be processed by the insurance
            company subject to their terms and conditions and the insurance
            policy.
          </p>
          <p>
            BeSecured reserves the sole right to modify, amend, change, refuse
            or revoke their respective services, any offers including third
            party offer herein at any time without assigning any reason and
            without any liability and notice.
          </p>
          <p>
            These terms and conditions for Nominee Assistance Program are in
            addition to all other terms and conditions provided hereunder
            including the FAQs and all these terms and conditions and FAQs are
            legally binding on the user. BeSecured reserves the sole right to
            modify, amend, change or revoke these terms and conditions including
            the FAQs without any notice at any time and all such terms and
            conditions and FAQs as amended from time to time shall be legally
            binding on the user.
          </p>
          <h5>Governing Law</h5>
          <p>
            This ToU Agreement and the Privacy Policy shall be governed and
            construed in accordance with the laws of the Republic of India and
            the courts at Mumbai, Maharashtra shall have exclusive jurisdiction
            on all matters and disputes arising out of and relating to the Site.
          </p>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

export default TermsAndConditions;
