import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/CMS.scss";
// import { COLORS } from "../../SupportingFiles/colors";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Footer from "../../Component/Desktop/Footer/Footer";
import { COLORS } from "../../SupportingFiles/colours";

function Aboutus() {
  const isMobile = useIsMobile();
  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box
        sx={{
          position: "relative",
          marginTop: "100px",
          marginBottom: "100px",
          "@media (max-width:420px)": {
            marginBottom: "130px",
          },
          ":before": {
            background: "#0A1534",
            width: "100%",
            height: "400px",
            content: "''",
            position: "absolute",
            top: "-50px",
            left: "0px",
            transform: "skew(0deg, 3deg)",
            zIndex: "-1",
            "@media (max-width:670px)": {
              height: "480px",
            },
            "@media (max-width:480px)": {
              height: "520px",
            },
            "@media (max-width:420px)": {
              height: "630px",
            },
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} sx={{ color: COLORS.white }}>
            <h2 className="productHeading">
              About <span style={{ color: COLORS.themered }}>us</span>
            </h2>
            <p
              style={{
                marginTop: "20px",
                lineHeight: "28px",
              }}
            >
              We are a pioneer in the field of insurance broking, with more than
              three-hundred-man years of expertise and experience. Our unique
              strength lies in our team of insurance professionals in handling
              complex claims and representing it on behalf of the insured's with
              the insurance companies.We have ensured that our technical
              expertise is capable of handling business across lines as the
              resources and expertise are not centralized, to provide the
              maximum support to our clients.Our vast presence across India with
              500+ executives allow us to service companies with multiple
              locations and businesses.
            </p>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        <Grid xs={12} lg={7}>
          <h4 style={{ color: COLORS.themeblue }}>
            About making insurance disitalised :
          </h4>
          <p>
            Extraordinary change is taking place throughout the insurance
            industry and everything that surrounds it.The whole world is going
            digital. It's the new reality, and there is no getting around it.
            But just the word or even the idea of digital has many people,
            insurers included, pondering the basic and fundamental questions:
          </p>
          <ul className="contentList">
            <li>Why do we need to be digital?</li>
            <li>How do we go digital?</li>
            <li>What, exactly, is digital anyway?</li>
          </ul>
        </Grid>
        <Grid xs={12} lg={5}>
          <img
            src="../images/aboutus-img.svg"
            width="100%"
            className="productHeadImg"
          />
        </Grid>
        <Grid xs={12}>
          <p className="mb-6">
            Defining digital is important because it's a broad topic with
            far-reaching implications. Simply put (and without getting into the
            science behind it), digital is way of doing things. And becoming
            digital is a state that will be required for moving around in the
            digital world: communicating, traveling, doing business, keeping a
            competitive edge and much, much more. AI and data are transforming
            the insurance space. The experiential phase of digital begins when
            digital assets come into use. Now digital becomes the method that
            undergirds the interaction. Digital assets are transmitted digitally
            to create digital experiences via portals, mobile apps, websites,
            sensors, wearable and many other digital things.
          </p>
          <p className="mb-6">
            Digitally enabled, future-ready operating models ensure long-term
            sustainability and Insurance is playing catch-up in delivering
            technology-enhanced customer experiences.
          </p>
          <p className="mb-6">
            Disruptions from environmental, technological, and organizational
            factors along with the ambitions of companies like us are making
            digitally agile operating models a necessity. New firms that use
            technology innovations to focus efficiencies and attract specific
            customer segments are leading the way with digital agility.
            Collaboration between traditional insurers and technically efficient
            firms is essential for the efficient and cost-effective development
            of digital capabilities across the industry. While insurance
            companies are unable to deliver a superior customer experience,
            using technology to fulfil their need is the order of the day. By
            enhancing digital agility and developing future-ready operating
            models, we have an opportunity to attract and retain customers to
            compete with this disruption. To succeed in the digital era, the
            insurance industry must foster digital capability and develop
            operating models to deliver a superior customer experience, bringing
            together the best of digital and traditional channels. However, to
            enhance the customer satisfaction with personalization, we need a
            digitally integrated ecosystem that seamlessly interconnects
            insurers with customers and partners, in order to enable an
            efficient flow of information and services.
          </p>
          <p className="mb-12">
            A digitally integrated ecosystem supports the real-time,
            personalized services that customers are growing to expect and
            demand. With enhanced digital agility, insurance firms can gain
            greater insight into customer needs and improve time to market for
            innovations, while driving greater operational efficiency and cost
            savings. In order make this a reality as a humble beginning we
            launch this platform which we hope would cater the Insurance needs
            of customers especially in semi-urban and rural part of our Country.
            Keeping in mind that customer service is the only factor that can be
            capitalized in any of the financial products, and going digital is
            the only way; we have made our portal as an integrated one for all
            the service needs that a customer can expect from each of the
            products.
          </p>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
}

export default Aboutus;
