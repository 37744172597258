import { Box, Link } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./Footer.scss";
// import { COLORS } from "../../../SupportingFiles/colors";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { COLORS } from "../../../SupportingFiles/colours";

function Footer() {
  const navigate = useNavigate();
  return (
    <Box className="desktopFooter pt-10 pb-3" sx={{ background: "#E6EDED" }}>
      <Grid container spacing={3}>
        <Grid xs={6} lg={3}>
          <ul>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                About Us
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}>
                Contact
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CLAIM)}>Claim</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CAREER)}>Career</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.NEED_HELP)}>
                Need Help?
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6} lg={3}>
          <ul>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.PRIVACY_POLICY)}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.TERM_AND_CONDTION)}>
                Terms and conditions
              </Link>
            </li>
            <li>
              <Link
                onClick={() => navigate(COMMON_ROUTES.REFUND_AND_CANCELLATION)}
              >
                Cancellation & Refunds
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.DISCLAIMER)}>
                Disclaimer
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6} lg={3}>
          <h6>Contact Details</h6>
          <p className="mb-3">
            Support mail:
            <br />{" "}
            <Link href="mailto:besecured@bharatre.in">
              besecured@bharatre.in
            </Link>
          </p>
          <p className="mb-3">
            Claim mail:
            <br />{" "}
            <Link href="mailto:claim.besecured@bharatre.in">
              claim.besecured@bharatre.in
            </Link>
          </p>
          <p className="mb-3">
            Endrosement mail:
            <br />{" "}
            <Link href="mailto:endo.besecured@bharatre.in">
              endo.besecured@bharatre.in
            </Link>
          </p>

          <p>
            Contact Number:
            <br /> 044-43418700
          </p>
        </Grid>
        <Grid xs={6} lg={3}>
          <h6 style={{ fontWeight: "700" }}>
            Bharat Re - Insurance Brokers Pvt Ltd
          </h6>
          <p className="mb-3">No. 6, Poes Road, Teynampet, Chennai - 600 018</p>

          <p>
            <span>CIN:</span> U67200TN1997PTC080219
          </p>
          <p className="mb-3">
            <span>GSTIN:</span> 33AAACI5036P1ZG
          </p>

          <p>Website approved by IRDA (Govt. of India), </p>
          <p>IRDA License Number : 104 </p>
          <p className="mb-3">Valid Till : 29/01/2027</p>

          <p>Principal Officer name : Mrs. Gowri Mani</p>
          <p>Contact number : 044-43418700</p>
          <p>
            Email ID :{" "}
            <Link href="mailto:support@bharatre.in">support@bharatre.in</Link>
          </p>
        </Grid>
        <Grid xs={12} textAlign="center">
          <p className="disclaimer">
            <span> Disclaimer:</span> Insurance is the subject matter of
            solicitation. Visitors are hereby informed that their information
            submitted on the website may be shared with insurers. The product
            information for comparison displayed on this website is of the
            insurers with whom our company has an agreement.Product information
            is authentic and solely based on the information received from the
            Insurer.
          </p>
          <p className="disclaimer">
            The information provided on this website/page is for information
            purpose only. besecured.in does not in any form or manner endorse
            the information so provided on the website and strives to provide
            factual and unbiased information to customers to assist in making
            informed insurance choices.
          </p>
          <h4
            style={{
              background: COLORS.white,
              fontWeight: "700",
              fontSize: "15px",
              padding: "4px 8px",
              lineHeight: "18px",
            }}
          >
            © 2024 Bharat Re - Insurance Brokers Private Limited All rights
            reserved.
          </h4>
          <p className="disclaimer mt-3">
            <span>besecured.in</span> and BeSecured.in Logo are registered
            trademarks of Bharat Re - Insurance Brokers Private Limited.
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
