import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../Component/Desktop/Footer/Footer";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../SupportingFiles/colours";
import "../../CommonSCSS/CMS.scss";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import CustomButton from "../../Component/CustomButton/CustomButton";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import RKTextField from "../../Component/Common/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../Component/Common/InputFields/SelectDropdown/SelectDropdown";

function Claim() {
  const isMobile = useIsMobile();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    insurer: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    claimfor: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    insurer: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    claimfor: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const claimForData = [
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "Term Insurance",
      value: "Term Insurance",
    },
    {
      label: "Car Insurance",
      value: "Car Insurance",
    },
    {
      label: "Bike Insurance",
      value: "Bike Insurance",
    },
  ];
  const insurerData = [
    {
      label: "Bajaj Allianz",
      value: "Bajaj Allianz",
    },
    {
      label: "Birla Sun Life",
      value: "Birla Sun Life",
    },
  ];
  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box
        sx={{
          position: "relative",
          marginTop: "124px",
          marginBottom: "100px",
        }}
      >
        <Grid container spacing={3} className="claimQuestion">
          <Grid xs={12}>
            <h3 style={{ color: COLORS.themeblue }}>
              Need <span style={{ color: COLORS.themered }}>Claim?</span>
            </h3>
            <p style={{ margin: "12px 0px", lineHeight: "28px" }}>
              We are here to help you when you have a claim - just contact us
            </p>
          </Grid>
          <Grid xs={12}>
            <h4 style={{ color: COLORS.themeblue }}>Claims</h4>

            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>How to make health insurance claims?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  A health insurance claim can be settled in a cashless or
                  reimbursement manner.
                </p>
                <p style={{ fontWeight: "600" }}>Cashless: </p>
                <p>
                  The Cashless claims are those for which the medical treatment
                  is sought at a network hospital of the insurance company. A
                  cashless claim can happen in two ways - planned treatment and
                  unplanned treatment.
                </p>
                <p style={{ fontWeight: "600" }}>Planned Treatment: </p>
                <p>
                  In case of a planned treatment, follow the below given steps:
                </p>
                <ul>
                  <li>
                    Inform the insurance company at least 4 days prior to taking
                    the treatment.
                  </li>
                  <li>
                    Submit a cashless claim request to the insurance company.
                  </li>
                  <li>
                    The policyholder and the hospital would get an eligibility
                    letter.
                  </li>
                  <li>
                    Present the confirmation letter with the cashless card to
                    the hospital at the time of hospitalisation.
                  </li>
                  <li>
                    Get the required treatment done. The treatment cost will be
                    borne by the insurance company.
                  </li>
                </ul>
                <p style={{ fontWeight: "600" }}>Unplanned Treatment: </p>
                <p>
                  Unplanned treatment is a treatment when there is an emergency
                  or some issue got diagnosed which needs immediate treatment.
                  In case of an unplanned treatment, follow the below given
                  steps:
                </p>
                <ul>
                  <li>
                    In case of an emergency, visit the nearest network hospital
                    and show the cashless health card at the hospital.
                  </li>
                  <li>Fill the claim form at the network hospital. </li>
                  <li>
                    The insurance company will send an authorisation letter to
                    the hospital after checking eligibility and coverage if the
                    policy.
                  </li>
                  <li>
                    You just have to get self treated; the charges would be
                    again borne by the insurance company.
                  </li>
                  <li>
                    In case of rejection, the company will send a letter to the
                    policyholder giving reasons for rejection.{" "}
                  </li>
                </ul>
                <p style={{ fontWeight: "600" }}>Reimbursement Claims: </p>
                <p>
                  The scenario of reimbursement arises when treatment is taken
                  at a non-network hospital. The reimbursement claimspan out
                  according to the below given steps:
                </p>
                <ul>
                  <li>
                    Take treatment at a non-network hospital, pay the bill
                    upfront from y own pocket.
                  </li>
                  <li>
                    Submit all the bills and necessary documents mentioned above
                    and raise a claim.
                  </li>
                  <li>
                    If the company deems the claim to be eligible then the
                    amount spent would be reimbursed.
                  </li>
                  <li>
                    If the claim is not reimbursed, the company will write an
                    explanatory letter stating the grounds of rejection.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                background: "rgba(33, 52, 105, 0.08)",
                padding: "32px",
                "@media (max-width:899px)": {
                  padding: "32px 8px",
                },
              }}
            >
              <Grid container spacing={3}>
                <Grid xs={12}>
                  {" "}
                  <h4 style={{ color: COLORS.themeblue }}>
                    Kindly furnish below details, we will get back to you.
                  </h4>
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Name"}
                    value={formFields.name.value}
                    attrName={"name"}
                    value_update={updateFieldsState}
                    warn_status={formFields.name.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Insurer"
                    attrName={"insurer"}
                    value={formFields.insurer.value}
                    value_update={updateFieldsState}
                    data={insurerData}
                    warn_status={formFields.insurer.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Claim for"
                    attrName={"claimfor"}
                    value={formFields.claimfor.value}
                    value_update={updateFieldsState}
                    data={claimForData}
                    warn_status={formFields.claimfor.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={formFields.mobile.value}
                    attrName={"mobile"}
                    value_update={updateFieldsState}
                    warn_status={formFields.mobile.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Email"}
                    value={formFields.email.value}
                    attrName={"email"}
                    value_update={updateFieldsState}
                    warn_status={formFields.email.warn_status}
                  />
                </Grid>
                <Grid xs={12} sm={6} lg={4} textAlign={"center"}>
                  <CustomButton
                    text_name="Submit Claim"
                    class_name="regularPrimaryBtn"
                    fullWidth={isMobile ? false : true}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid xs={12}>
            <h4 style={{ color: COLORS.themeblue }}>Documents</h4>

            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel10bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  List of documents required for motor insurance claim
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>Registration Certificate (RC) Copy</li>
                  <li>Policy Copy</li>
                  <li>Policy holder's valid ID proof</li>
                  <li>
                    Drivers Driving license - Who drove the vehicle at the time
                    of accident.
                  </li>
                  <li>FIR Copy (In case of theft and Third Party claim)</li>
                  <li>Duly filled Claim form</li>
                  <li>
                    Any further requirements will be conveyed basis inspection
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel11bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  List of documents required for health insurance claim
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  <li>
                    <span>Filled Claim Form:</span> The very first document
                    should be submitted is the claim form that should be filled
                    by the policyholder.
                  </li>
                  <li>
                    <span>Medical Certificate:</span> A medical certificate
                    issued by y doctor and authorised by the hospital.
                  </li>
                  <li>
                    <span>Diagnosis Report:</span> A diagnosis report should be
                    attached which should give the information about y health
                    issue.
                  </li>
                  <li>
                    <span>ID Proof:</span> ID Proof is necessary to attach for
                    claim.
                  </li>
                  <li>
                    <span>Discharge Card:</span> The patients need to submit the
                    discharge card to the insurance company along with the
                    claim.
                  </li>
                  <li>
                    <span>Prescription And Medical Bills:</span> The doctor’s
                    prescription should be attached to the claim along with the
                    bills of the prescription drugs and invoice of hospital and
                    nursing charges.
                  </li>
                  <li>
                    <span>FIR (In Case Of Accident):</span> If there is an
                    accident then the first information report or the medico
                    legal certificate needs to be attached to claim.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel12bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  List of documents required for travel insurance claim
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <p>
                  No one will thinks that there is a probability of making a
                  claim while buying a travel insurance plan. Hover, unfortunate
                  incidents are unpredictable and unavoidable. Any traveller can
                  face any travel-related issues. If one has extensive coverage,
                  the insurance company's claim team will take all the care of
                  the claim settlement. The exact type of supporting documents
                  required for claim settlement will be based on the issue faced
                  and the insurance company's policies. Listed below are some
                  documents that are needed in case of a travel insurance claim.
                </p>
                <ul>
                  <li>
                    <span>Claim form:</span> We might need to fill an offline or
                    online claim to initiate the claim settlement process from
                    our side.
                  </li>
                  <li>
                    <span>Policy document:</span> The insurance policy is one of
                    the most essential documents. It might have personal details
                    or dedicated customer numbers that say that the policy
                    belongs to us. This is the first thing that the insurance
                    company will notice in case of a claim. They will also
                    cross-check the problem faced with the coverage in the
                    travel policy issued. If the issue is covered, the claim's
                    team will move the inspection process forward. If not, then
                    the claim will get rejected.
                  </li>
                  <li>
                    <span>Identification:</span> Some insurance companies might
                    ask you to submit some type of identification documents like
                    Aadhar copy or passport copy.
                  </li>
                  <li>
                    <span>Trip tickets:</span> In case of international travel
                    insurance, we might have to produce the flight tickets. Such
                    type of documentation comes in handy in case of claims
                    related to trip cancellations, trip delay, and so on.
                  </li>
                  <li>
                    <span>Cancellation:</span> If there is a trip cancellation
                    related to a medical issue, the insurance company might ask
                    for a doctor's statement stating the same.
                  </li>
                  <li>
                    <span>Airport correspondence:</span> Such kind of
                    documentation becomes essential in case of baggage
                    delay/loss. We need to get the suitable correspondence from
                    the airport authorities that verifies the claim.
                  </li>
                  <li>
                    <span>FIR:</span> A first information report is needed for
                    claims related to the theft of baggage. Detailed reports
                    need to be collected from the allotted police officials.
                  </li>
                  <li>
                    <span>Loss of passport:</span> If we lose thepassport on the
                    foreign trip, themust submit a photocopy of the document
                    with the claim form.
                  </li>
                  <li>
                    <span>Hospital Bill:</span> In case of an unfortunate event
                    of an accident, wehave to submit the appropriate hospital
                    bills. This is applicable if we have gone for a
                    reimbursement claim and not a cashless one.
                  </li>
                  <li>
                    <span>Discharge Card:</span> In case there is a prolonged
                    stay at a hospital, wehave to submit a discharge card from
                    the hospital where we get treated. Again, this is not
                    assuring in case of cashless claims.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid xs={12}>
            <Box
              border="1px dashed #213469"
              borderRadius="12px"
              padding="20px"
              display="flex"
              gap="12px"
              justifyContent="center"
            >
              <img src="../images/phone.svg" width="50px" />
              <p style={{ fontWeight: "600" }}>
                For Instant claim, feel free to talk to us:
                <span style={{ display: "block", fontWeight: "400" }}>
                  +91 96325 85236, +91 96582 63259
                </span>
              </p>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </Box>
  );
}

export default Claim;
