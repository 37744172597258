import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  DialogTitle,
  Dialog,
} from "@mui/material";
// import { COLORS } from "../../../SupportingFiles/colors";
import "./Navbar.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomButton from "../../CustomButton/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { COLORS } from "../../../SupportingFiles/colours";

const emails = ["username@gmail.com", "user02@gmail.com"];

interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog open={open} className="modalPopup">
      <Box padding="24px">
        {/* Step one */}
        <Grid container spacing={2} textAlign="center">
          <Grid xs={8} xsOffset={2}>
            <h4>Welcome</h4>
            <p>Please login and get your all policies details</p>
          </Grid>
          <Grid xs={2} textAlign="right">
            <Link className="close" onClick={handleClose}>
              <CloseIcon />
            </Link>
          </Grid>
          <Grid xs={12} className="my-4">
            <img src="../images/login_img1.svg" width="90%" />
          </Grid>
          <Grid xs={12}>Input field will be here</Grid>
          <Grid xs={12}>
            <CustomButton text_name="Send OTP" class_name="regularPrimaryBtn" />
          </Grid>
        </Grid>

        {/* Step Two */}
        <Grid container spacing={2} textAlign="center" display="none">
          <Grid xs={8} xsOffset={2}>
            <h4>Verify OTP</h4>
            <p>
              OTP has been sent successfully on <br />
              +91 - <span style={{ fontWeight: "600" }}> 9876543210</span>{" "}
              <Link
                sx={{
                  display: "inline-flex",
                  color: COLORS.themered,
                  alignItems: "center",
                  position: "relative",
                  top: "6px",
                  left: "4px",
                }}
              >
                <EditNoteIcon /> Edit
              </Link>
            </p>
          </Grid>
          <Grid xs={2} textAlign="right">
            <Link className="close" onClick={handleClose}>
              <CloseIcon />
            </Link>
          </Grid>
          <Grid xs={12} className="my-4">
            <img src="../images/login_img1.svg" width="90%" />
          </Grid>
          <Grid xs={12}>Input field will be here</Grid>
          <Grid xs={12}>
            <CustomButton text_name="Submit" class_name="regularPrimaryBtn" />
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

function Navbar() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addBoxShadow, setAddBoxShadow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if (position > 70) {
        setAddBoxShadow(true);
      } else {
        setAddBoxShadow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(emails[0]);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (value: string) => {
    setDialogOpen(false);
    setSelectedValue(value);
  };

  return (
    <Box
      sx={{
        background: COLORS.white,
        padding: "24px 12px",
        position: "fixed",
        top: "0px",
        left: "0px",
        boxShadow: addBoxShadow ? "0px 4px 12px rgba(0,0,0, 0.1)" : "none",
        zIndex: "91",
        width: "100%",
      }}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid xs="auto">
          <Link
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <img
              src="../images/BeScured_logo.svg"
              height="50px"
              alt="BeSecured"
            />
          </Link>
        </Grid>
        <Grid xs>
          <ul className="navLinks">
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.HOME)}>Home</Link>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.ABOUT_US)}>
                About us
              </Link>
            </li>
            <li>
              <Button
                id="basic-button"
                aria-controls={openMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={handleClick}
              >
                Products <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  Car Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  Bike Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}>
                  Term Insurance
                </MenuItem>
                {/* <MenuItem onClick={handleCloseMenu}>Investment Plan</MenuItem>
                <MenuItem onClick={handleCloseMenu}>Travel Insurance</MenuItem> */}
              </Menu>
            </li>
            <li>
              <Link onClick={() => navigate(COMMON_ROUTES.CONTACT_US)}>
                Contact us
              </Link>
            </li>
            {/* <li>
              <CustomButton
                text_name="Login"
                class_name="mediumPrimaryBtn"
                onClickFunction={handleDialogOpen}
              />
            </li> */}
          </ul>
        </Grid>
      </Grid>
      <SimpleDialog
        selectedValue={selectedValue}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </Box>
  );
}

export default Navbar;
