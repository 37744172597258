let nodeDomain, strapiDomain, phpDomain, frontendDomain;

if (process.env.REACT_APP_ENV === "DEV") {
  nodeDomain = "https://devnodeapi.besecured.in";
  strapiDomain = "https://strapi.besecured.in";
  phpDomain = "https://devapi.besecured.in";
  frontendDomain = "https://dev2.besecured.in";
} else if (process.env.REACT_APP_ENV === "STG") {
  nodeDomain = "https://stgnodeapi.besecured.in";
  strapiDomain = "https://strapi.besecured.in";
  phpDomain = "https://stgapi.besecured.in";
  frontendDomain = "https://stg.besecured.in";
} else if (process.env.REACT_APP_ENV === "PROD") {
  nodeDomain = "https://nodeapi.besecured.in";
  strapiDomain = "https://strapi.besecured.in";
  phpDomain = "https://api.besecured.in";
  frontendDomain = "https://besecured.in";
} else {
  // Default to dev environment
  nodeDomain = "https://devnodeapi.besecured.in";
  strapiDomain = "https://strapi.besecured.in";
  phpDomain = "https://devapi.besecured.in";
  frontendDomain = "";
}

export const NODE_DOMAIN = nodeDomain;
export const STRAPI_DOMAIN = strapiDomain;
export const PHP_DOMAIN = phpDomain;
export const FRONTEND_DOMAIN = frontendDomain;
