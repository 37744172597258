import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../Component/Desktop/Footer/Footer";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../SupportingFiles/colours";
import "../../CommonSCSS/CMS.scss";
import CustomButton from "../../Component/CustomButton/CustomButton";
import RKTextField from "../../Component/Common/InputFields/RKTextField/RKTextField";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { useRef, useState } from "react";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import TextArea from "../../Component/Common/InputFields/TextArea/TextArea";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function Career() {
  const isMobile = useIsMobile();
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    location: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    location: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState("Upload Resume");

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile.name);
    } else {
      setFile("Upload your CV in .jpg, .png, or .pdf format");
    }
  };
  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box
        sx={{
          position: "relative",
          marginTop: "124px",
          marginBottom: "100px",
        }}
      >
        <Grid container spacing={3} className="claimQuestion">
          <Grid xs={12} sm={6}>
            <h3 style={{ color: COLORS.themeblue }}>
              Join <span style={{ color: COLORS.themered }}>us</span>
            </h3>
          </Grid>
          <Grid xs={12} sm={6}>
            <img src="../images/career-img.svg" width="100%" />
          </Grid>

          <Grid xs={12}>
            <Box
              sx={{
                borderRadius: "20px",
                background: "rgba(33, 52, 105, 0.08)",
                padding: "32px",
                "@media (max-width:899px)": {
                  padding: "32px 8px",
                },
              }}
            >
              <Grid container spacing={3}>
                <Grid xs={12}>
                  {" "}
                  <h4 style={{ color: COLORS.themeblue }}>
                    Kickstart Your Career With Us
                  </h4>
                </Grid>
                <Grid
                  xs={12}
                  sm={6}
                  display={"flex"}
                  flexDirection={"column"}
                  gap="24px"
                >
                  <RKTextField
                    class_name="inputField"
                    title={"Name"}
                    value={formFields.name.value}
                    attrName={"name"}
                    value_update={updateFieldsState}
                    warn_status={formFields.name.warn_status}
                  />
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile"}
                    value={formFields.mobile.value}
                    attrName={"mobile"}
                    value_update={updateFieldsState}
                    warn_status={formFields.mobile.warn_status}
                  />
                  <RKTextField
                    class_name="inputField"
                    title={"Current Location"}
                    value={formFields.location.value}
                    attrName={"location"}
                    value_update={updateFieldsState}
                    warn_status={formFields.location.warn_status}
                  />
                  <Box className="upload_section">
                    <CloudUploadIcon />
                    <Box className="fileUpload">
                      <label className="custom-file-upload">
                        <span>{file}</span>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          onClick={handleFileSelect}
                        />
                      </label>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} textAlign={"center"}>
                  <TextArea
                    class_name="inputField mb-6"
                    title={"Describe"}
                    value={formFields.message.value}
                    attrName={"message"}
                    value_update={updateFieldsState}
                    warn_status={formFields.message.warn_status}
                    placeholder="Write here..."
                    rows={7}
                  />
                  <CustomButton
                    text_name="Submit Resume"
                    class_name="regularPrimaryBtn"
                    fullWidth={isMobile ? false : true}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
}

export default Career;
