import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../Component/Desktop/Footer/Footer";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import { COLORS } from "../../SupportingFiles/colours";
import "../../CommonSCSS/CMS.scss";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import CustomButton from "../../Component/CustomButton/CustomButton";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import RKTextField from "../../Component/Common/InputFields/RKTextField/RKTextField";
import { useState } from "react";
import TextArea from "../../Component/Common/InputFields/TextArea/TextArea";

function Contact() {
  const isMobile = useIsMobile();
  const [formFields, setFormFields] = useState<{
    name: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    message: { value: string; warn_status: boolean };
  }>({
    name: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    message: { value: "", warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}
      <Box
        sx={{
          position: "relative",
          marginTop: "100px",
          marginBottom: "100px",
          ":before": {
            background: "#0A1534",
            width: "100%",
            height: "420px",
            content: "''",
            position: "absolute",
            top: "-50px",
            left: "0px",
            transform: "skew(0deg, 3deg)",
            zIndex: "-1",
            "@media (max-width:899px)": {
              height: "360px",
            },
          },
          "@media (max-width:899px)": {
            marginBottom: "40px",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} md={6} sx={{ color: COLORS.white }}>
            <h2 className="productHeading">
              <span style={{ color: COLORS.themered }}>Get in touch</span> with
              us for all your insurance requirements.
            </h2>
            <p style={{ marginTop: "24px", lineHeight: "28px" }}>
              Besecured will ensure you get value for money and value added
              services to ensure that all your risk exposures are well
              protected.
            </p>
            <img
              src="../images/contact-img.svg"
              width="100%"
              className="productHeadImg"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                background: COLORS.white,
                borderRadius: "12px",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                padding: "32px",
                marginTop: "60px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                "@media (max-width:899px)": {
                  padding: "32px 20px",
                  marginTop: "30px",
                },
              }}
            >
              <h4>Please furnish details, we will get back to you.</h4>
              <RKTextField
                class_name="inputField"
                title={"Name"}
                value={formFields.name.value}
                attrName={"name"}
                value_update={updateFieldsState}
                warn_status={formFields.name.warn_status}
              />
              <RKTextField
                class_name="inputField"
                title={"Email"}
                value={formFields.email.value}
                attrName={"email"}
                value_update={updateFieldsState}
                warn_status={formFields.email.warn_status}
              />
              <RKTextField
                class_name="inputField"
                title={"Mobile"}
                value={formFields.mobile.value}
                attrName={"mobile"}
                value_update={updateFieldsState}
                warn_status={formFields.mobile.warn_status}
              />
              <TextArea
                class_name="inputField"
                title={"Describe"}
                value={formFields.message.value}
                attrName={"message"}
                value_update={updateFieldsState}
                warn_status={formFields.message.warn_status}
                placeholder="Write here..."
                rows={4}
              />
              <CustomButton
                text_name="Call Me Back"
                class_name="regularPrimaryBtn"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} marginBottom={"20px"}>
        <Grid xs={12} lg={6}>
          <Box
            sx={{
              background: "#E6EDED",
              borderRadius: "20px",
              boxShadow: "4px 4px 0px #213469",
              padding: "20px 12px",
              textAlign: "center",
              marginBottom: "40px",
              "@media (max-width:899px)": {
                marginBottom: "20px",
              },
            }}
          >
            <img src="../images/phone.svg" width="50px" />
            <h5 style={{ fontWeight: "600", margin: "12px" }}>Phone:</h5>
            <ul style={{ display: "flex", flexWrap: "wrap", rowGap: "12px" }}>
              <li
                style={{
                  fontSize: "16px",
                  width: "48%",
                  fontFamily: "Poppins",
                }}
              >
                Telgu: 9494486864
              </li>
              |
              <li
                style={{
                  fontSize: "16px",
                  width: "48%",
                  fontFamily: "Poppins",
                }}
              >
                Kanada: 9606707646
              </li>
              <li
                style={{
                  fontSize: "16px",
                  width: "48%",
                  fontFamily: "Poppins",
                }}
              >
                Tamil: 9840139724
              </li>
              |
              <li
                style={{
                  fontSize: "16px",
                  width: "48%",
                  fontFamily: "Poppins",
                }}
              >
                Hindi: 8248218955
              </li>
            </ul>
            <p className="mt-3">General Queries: 9840139781</p>
          </Box>
        </Grid>
        <Grid xs={12} lg={6}>
          <Box
            sx={{
              background: "#E6EDED",
              borderRadius: "20px",
              boxShadow: "4px 4px 0px #213469",
              padding: "20px 12px",
              textAlign: "center",
              marginBottom: "40px",
              "@media (max-width:899px)": {
                marginBottom: "20px",
              },
            }}
          >
            <img src="../images/email.svg" width="50px" />
            <h5 style={{ fontWeight: "600", margin: "12px" }}>Email:</h5>
            <p className="mt-3">besecured@bharatre.in</p>
          </Box>
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
}

export default Contact;
