import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import "../Desktop/CMS.scss";
import Navbar from "../../Component/Desktop/Navbar/Navbar";
import Footer from "../../Component/Desktop/Footer/Footer";
import { COLORS } from "../../SupportingFiles/colours";
import CustomButton from "../../Component/CustomButton/CustomButton";
import MNavbar from "../../Component/Mobile/MNavbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import TermProductContainer from "../../Container/TermProductContainer/TermProductContainer";

function TermProductPage() {
  const isMobile = useIsMobile();
  return (
    <Box>
      {isMobile ? <MNavbar /> : <Navbar />}

      <Box
        sx={{
          position: "relative",
          marginTop: "100px",
          ":before": {
            background: "#0A1534",
            width: "100%",
            height: "450px",
            content: "''",
            position: "absolute",
            top: "-50px",
            left: "0px",
            transform: "skew(0deg, 3deg)",
            zIndex: "-1",
          },
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} md={6} sx={{ color: COLORS.white }}>
            <h2 className="productHeading">
              <span style={{ color: COLORS.themered }}>Term</span> Insurance
            </h2>
            <p style={{ marginTop: "24px", lineHeight: "28px" }}>
              While we might not like to think about death, it’s a reality for
              everybody. And sometimes, it comes too early, causing financial
              hardship in addition to emotional pain.
            </p>
            <img
              src="../images/term-product-img.svg"
              width="100%"
              className="productHeadImg"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                background: COLORS.white,
                borderRadius: "12px",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                padding: "32px 20px",
                marginTop: "120px",
                marginBottom: "24px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                "@media (max-width:800px)": {
                  marginTop: "24px",
                },
              }}
            >
              <TermProductContainer />
              {/* <CustomButton
                text_name="View Quotes"
                class_name="regularPrimaryBtn"
              /> */}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} className="productPages">
        <Grid xs={12}>
          <p>
            Life insurance can help with the financial impact of death, but
            people often don't understand how it works and how much it costs. In
            many cases, life insurance is relatively inexpensive and provides
            crucial protection for loved ones.
          </p>
          <p>
            Life insurance provides much-needed funding when tragedy strikes,
            and being underinsured can be extremely risky.
          </p>

          <h4>Life Insurance is needed to protect dependents</h4>
          <p>
            If a spouse, children, or other loved ones depend on us, there's a
            good chance we need life insurance. If we are a wage-earner in the
            family, our death would leave dependents without a vital source of
            income. The result could be a domino effect of financial hardships
            that last for years. This is because lost income makes it hard to
            save for goals like education, which can mean children enter the
            workforce with heavy student debt. It's also much more difficult to
            save for retirement if a spouse or partner has to support a family
            on their own.
          </p>
          <h4>What are the benefits of life insurance?</h4>
          <p>
            Most people understand the primary benefits of having life
            insurance: Your family gets money if you die unexpectedly - and you
            get the reassurance of knowing they'll have resources to help carry
            on without you. While those benefits are generally true for all
            kinds of life insurance, there are other important advantages
            depending on the specific type of policy and amount of coverage you
            get. There are also benefits for women, too.
          </p>
          <p>
            It can help take care of your family if something happens - but
            that's only one of the benefits of life insurance.
          </p>
          <ul>
            <li>
              All life insurance can give you financial confidence that your
              family will have financial stability in your absence. But
              generally, the more life insurance you have, the more benefits it
              will provide to your family when needed.
            </li>
            <li>Income replacement for years of lost salary</li>
            <li>Paying off your home mortgage</li>
            <li>
              Paying off other debts, such as car loans, credit cards, and
              student loans
            </li>
            <li>Providing funds for your kids' college education</li>
            <li>
              Helping with other obligations, such as care for aging parents.
            </li>
            <li>
              Many policies have valuable “riders” or contractual provisions
              that provide benefits before death.
            </li>
          </ul>
          <h4>
            There are two basic kinds of life insurance: Term and permanent like
            whole life.
          </h4>
          <p>
            With a term life policy, you pay a specific premium for a defined
            term (say 10 years). If any eventuality during that time, a death
            benefit is paid to your beneficiaries - but when the term is over
            you must get new coverage or go without. A whole life policy is
            permanent life insurance that last your entire life.
          </p>
          <h4>What are the benefits 0f Term Life insurance?</h4>
          <ul>
            <li>Typically, lower cost</li>
            <li>Simpler to understand - it's purely an insurance product</li>
            <li>
              It may be convertible to whole life - but find out before you buy
            </li>
            <li>
              If you no longer need it or can't afford it, you can walk away
              without losing anything more than the premiums already paid
            </li>
          </ul>
          <h4>What are the benefits of whole life insurance?</h4>
          <ul>
            <li>Permanent life insurance</li>
            <li>
              Contains an important savings element known as cash value that you
              can take out or borrow against.
            </li>
            <li>Can provide tax-advantaged estate planning benefits .</li>
          </ul>
        </Grid>
      </Grid>

      <Footer />
    </Box>
  );
}

export default TermProductPage;
