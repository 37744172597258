import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./Store/Store/Store";
import { Box, CircularProgress } from "@mui/material";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Sentry.init({
  dsn: "https://cdd89f855e207248c1c3ad80802a2754@o4506864219521024.ingest.us.sentry.io/4507571326877696",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <Provider store={store}>
    <PersistGate
      loading={
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      }
      persistor={persistor}
    >
      <ToastContainer theme="colored" />
      <App />
    </PersistGate>
  </Provider>
);

reportWebVitals();
